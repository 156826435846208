'use strict';

// @ngInject
module.exports = function () {

    return {
        scope: {
            id: '@'
        },
        templateUrl: '/public/scripts/directives/checkmark/checkmark.template.html',
        restrict: 'E',
        replace: true,
        transclude: true,
        controllerAs: 'vm',
        controller: ['$scope', '$element', '$timeout', '$window', checkmarkCtrl],
        bindToController: true
    };
};

function checkmarkCtrl($scope, $element, $timeout, $window) {
    $scope.switchOn = function() {
        $timeout(function() {
            $scope.checkMarkOn = true;
            $timeout(function() {
                $scope.checkMarkOn = false;
            }, 2000)
        });
    }

    $scope.$on('show-checkmark', function(event, data) {
        $scope.switchOn();
    });
}