'use strict';

// @ngInject
module.exports = function () {
  return {
    restrict: 'E',
    item: '=item',
    scope: true,
    templateUrl: '/public/scripts/directives/media-row/media-row.template.html',
    transclude: true,
    controller: ['$scope', '$element', '$timeout', '$http', mediaRowCtrl]
  };
};

function mediaRowCtrl($scope, $element, $timeout, $http) {
  $scope.selectItem = function ($event, item) {
    var target = $event.currentTarget;
    target.classList.add('selected');
    $scope.selectedDetails.fullDetails = true;
    document.getElementById('details').scrollTop = 0;
    $scope.selectedDetails.selectionOn = $scope.selectionOn;
    $scope.selectedDetails.selectedItem = item;
    $timeout(function () {
      target.classList.remove('selected');
    }, 300);
  };

  $scope.imdbRating = function (val) {
    return getImdbRating(val);
  }

  $scope.showSize = function (bytes) {
      if (bytes.toString().toUpperCase().indexOf("GB") != -1) {
        return bytes;
      }
      if (!+bytes) return '0 Bytes'
      const decimals = 2;
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  function getImdbRating(val) {
    if (!val || !val.Ratings || val.Ratings.length == 0) {
      return false;
    }
    var rating = 0;
    if (val.rating) {
      rating = val.rating.split('/')[0];
    } else {
      rating = parseFloat(val.Ratings[0].Value);
      if (val.Ratings[0].Value.indexOf('%') != -1) {
        rating = rating / 10;
      } else if (val.Ratings[0].Value.indexOf('/100') != -1) {
        rating = rating / 10;
      }
    }
    return (rating / 2);
  }

  $scope.imdbRatingBackground = function (val) {
    const rating = getImdbRating(val);
    if (!rating) {
      return;
    }
    if (rating >= 4.25) {
      return '#f5f500'
    } else if (rating >= 3.5) {
      return '#cccc00';
    } else if (rating >= 2.5) {
      return '#a3a300'
    } else if (rating >= 1.5) {
      return '#cc8500'
    } else {
      return '#a36a00';
    }
  }
}


