const LZString = require('lz-string');

module.exports = function ($rootScope, $scope, $http, $timeout, moment, dialogHelper) {
  const DIALOG = 'HOME';
  let lastTime = '';

  $scope.isLoading = false;
  $scope.checkingUpdates = false;
  $scope.selectionOn = 'fourk';
  let newInfoHashVals = [];
  $scope.selectedCategory = 'top';
  $scope.pbJson = [];
  $scope.updatedItems = [];
  $scope.selectedDetails = {
    fullDetails: false,
    selectedItem: {},
    filterRecent: false,
    filterText: '',
    filter: 'all'
  };
  $scope.lastupdated = '';

  $scope.new = {
    tv4k: 0,
    hd: 0,
    music: 0,
    fourk: 0,
    tv: 0
  };

  $scope.items = [];

  $timeout(function () {
    init();
  });

  function init() {
    // Display the existing PB data before checking if we need any more.
    newInfoHashVals = localStorage.getItem('newInfoHashVals');
    // Get the service worker version
    const buildInfo = {};
    buildInfo.serviceWorkerVersionDate = SW_BUILD;
    buildInfo.gitCommit = GIT_COMMIT;
    buildInfo.landscape = 'NAS';
    $rootScope.buildInfo = buildInfo;
    $scope.isFocussed = true;
    try {
      const compressedPbJson = localStorage.getItem('compressedPbJson');
      if (compressedPbJson) {
        displayPbDetails(compressedPbJson);
      }
    } catch (e) { }
    checkLoadData();
    getActiveDownloads();
  }

  $scope.$on('checkLoadData', function () {
    getActiveDownloads();
    checkLoadData();
  });

  // Check if the data we have needs to be refreshed.

  function checkLoadData() {
    if ($scope.checkingUpdates == true || $scope.isLoading == true) {
      // already checking.
      console.log('already checking or loading');
      return;
    }
    $scope.checkingUpdates = true;
    $scope.isFocussed = true;
    $http.get('/dynamic/time?' + new Date().valueOf()).then(function (response) {
      lastTime = response.data.time;
      var ip = response.data.ip;
      const previousLastTime = localStorage.getItem('lastTime');
      if (lastTime != previousLastTime) {
        loadData(ip);
      }
      $timeout(function () {
        $scope.checkingUpdates = false;
      }, 500);
    }).catch(function () {
      $timeout(function () {
        $scope.checkingUpdates = false;
      });
    });
  }

  $scope.getDownloadsClass = function () {
    for (var i = 0; i < $scope.downloadList.length; i++) {
      if ($scope.downloadList[i].status == 'inprogress') {
        return "downloads-inprogress";
      }
    }
    return "downloads-completed";
  }

  $scope.$on('stopDownloadsCheck', function () {
    $scope.isFocussed = false;
  });

  function getActiveDownloads() {
    let url = '/dynamic/downloads?now=' + new Date().valueOf();
    $http.get(url).then(function (response) {
      if (response) {
        $scope.downloadList = response.data;
      }
      if ($scope.isFocussed) {
        $timeout(function () {
          getActiveDownloads();
        }, 2500);
      }
    });
  }

  $scope.doClearDownloads = function () {
    $timeout(function () {
      dialogHelper.showLoading('Checking downloads, please wait');
      let url = '/dynamic/cleardownloads?now=' + new Date().valueOf();
      $http.get(url).then(function (response) {
        $scope.downloadList = response.data;
        dialogHelper.closeDialog();
      });
    }, 300);
  };

  $scope.setSelection = function (val) {
    if ($scope.selectionOn == val && val !== 'downloads' && val != 'torrents4k') {
      if ($scope.selectedCategory == 'top') {
        $scope.setSelectedCategory('all');
      } else {
        $scope.setSelectedCategory('top');
      }
    } else {
      $scope.selectionOn = val;
      setItems();
    }
  };

  function loadData(ip) {
    $scope.progress = 0;
    document.getElementById('loading-bar').style.width = '0%';
    $timeout(function () {
      $scope.isLoading = true;
    }, 100);
    var jsonPrefix = '';
    if (ip && ip.indexOf('192.168') != -1) {
      jsonPrefix = 'https://pblocal.culdaff.net:4303';
    }
    $http({
      method: 'GET',
      url: jsonPrefix + '/dynamic/pbjson?' + new Date().valueOf(),
      headers: {
        'Cache-Control': 'no-transform'
      }
    }).then(function (response) {
      $scope.progress = 100;
      localStorage.setItem('compressedPbJson', response.data.compressed);
      newInfoHashVals = response.data.newVals;
      localStorage.setItem('newInfoHashVals', newInfoHashVals);
      displayPbDetails(response.data.compressed);
      $timeout(function () {
        $scope.isLoading = false;
        localStorage.setItem('lastTime', lastTime);
        $timeout(function () {
          document.getElementById('loading-bar').style.width = '0%';
          $scope.progress = 0;
        }, 300);
      }, 300);
    });
  }

  $scope.$on('close-more', function (event, data) {
    let closeThisDialog = true;
    if (data) {
      if (data.id !== DIALOG) {
        closeThisDialog = false;
      }
    }
    if (closeThisDialog && $scope.moreDetails) {
      $scope.selectionOn = $scope.oldSelectionOn;
      $scope.moreDetails = false;
      $scope.moreItemDetails = null;
      $scope.purgeDownloadsCheck = false;
      $scope.addingDownload = false;
      $scope.checkingDownloads = false;
    }
  });

  $scope.showMore = function () {
    // dialogHelper.closeOthers(DIALOG);
    $scope.oldSelectionOn = $scope.selectionOn;
    $scope.selectionOn = 'more';
    $scope.moreDetails = true;
  };

  $scope.unselectMore = function () {
    // dialogHelper.closeDialog();
    $scope.$broadcast('close-more');
  };

  $scope.confirmLoadMissingImdb = function () {
    $scope.missingImdb = getMissingImdb();
    if ($scope.missingImdb.total == 0) {
      dialogHelper.showAlert(
        'There are no IMDB items missing from this section'
      );
    } else {
      dialogHelper.openDialog('imdb-confirm');
    }
  }

  $scope.doLoadMissingImdb = function () {
    dialogHelper.openDialog('imdb-missing');
    $scope.missingImdb.processing = 0;
    doLoadOneImdb();
  };

  function doLoadOneImdb() {
    if ($scope.missingImdb.processing < $scope.missingImdb.total) {
      const postData = {
        title: $scope.missingImdb.items[$scope.missingImdb.processing].title,
        cat: $scope.selectionOn,
        titleSearch: false
      };
      let url = '/dynamic/findImdb?now=' + new Date().valueOf();
      $http.post(url, JSON.stringify(postData)).then(function (response) {  
        if (!response.data || response.data == 'error' || response.data.Response == 'False') {
          $scope.missingImdb.failed++;
          $scope.missingImdb.processing++;
          doLoadOneImdb();
        } else {
          let imdbId = null;
          if (response.data.Title) {
            imdbId = response.data.imdbID;
          } else if (response.data.Search.length > 0) {
            imdbId = response.data.Search[0].imdbID;
          }
          if (imdbId) {
            url = '/dynamic/updateImdb?now=' + new Date().valueOf();
            const postData = {
              imdb: imdbId,
              id: $scope.missingImdb.items[$scope.missingImdb.processing].id
            };
            $http.post(url, JSON.stringify(postData)).then(function (response) {
              if (!response.data || !response.data.imdb) {
                $scope.missingImdb.failed++;
                $scope.missingImdb.processing++;
                doLoadOneImdb();
              } else {
                $scope.missingImdb.items[$scope.missingImdb.processing].imdb = response.data.imdb;   
                $scope.missingImdb.success++;
                $scope.missingImdb.processing++;
                doLoadOneImdb();
              }
            });
          } else {
            $scope.missingImdb.failed++;
            $scope.missingImdb.processing++;
            doLoadOneImdb();
          }
        }  
      });
    }
  }

  $scope.unselectItem = function () {
    $scope.selectedDetails.fullDetails = false;
  };

  $scope.setSelectedCategory = function (val) {
    $scope.selectedCategory = val;
    setNewLabels();
    setItems();
  };

  function getMissingImdb() {
    var retarray = [];
    for (var item of $scope.items) {
      if (!item.imdb) {
        retarray.push(item);
      }
    }
    return {
      items: retarray,
      total: retarray.length,
      failed: 0,
      success: 0,
      processing: 0,
      perc() {        
        var tp = parseFloat(this.processing / this.total) * 100;
        return tp;
      }
    }
  }

  function setNewLabels() {
    let jsonKey = '';
    if ($scope.selectedCategory == 'top') {
      jsonKey = 'top';
    } else {
      jsonKey = 'category';
    }
    let newVals = [];
    for (let sections of $scope.pbJson) {
      if (sections.section.includes(jsonKey)) {
        let count = 0;
        for (let item of sections.items) {
          if (newInfoHashVals.includes(item.infoHash)) {
            item.isNew = true;
            count++;
          }
        }
        let key = getSectionKeyForSection(sections.section);
        newVals.push({
          key: key,
          count: count
        });
      }
    }
    for (let newVal of newVals) {
      $scope.new[newVal.key] = newVal.count;
    }
  }

  function getSectionKeyForSection(section) {
    if (section.includes('207')) {
      return 'hd';
    } else if (section.includes('212')) {
      return 'tv4k';
    } else if (section.includes('101')) {
      return 'music';
    } else if (section.includes('211')) {
      return 'fourk';
    } else if (section.includes('208')) {
      return 'tv';
    }
    return '';
  }

  $scope.switchFilter = function () {
    if ($scope.selectedCategory == 'top') {
      $scope.setSelectedCategory('all');
    } else {
      $scope.setSelectedCategory('top');
    }
  };

  function setItems() {
    if ($scope.selectionOn == 'downloads') {
      return;
    } else if ($scope.selectionOn == 'torrents4k') {
      console.log('TORRENTS:', $scope.torrents);
      for (var i = 0; i < $scope.torrents.length; i++) {
        $scope.torrents[i].dateUploadedTxt = getDateUploadedTxt(parseFloat(new Date($scope.torrents[i].dateUploaded).getTime() / 1000));
        $scope.torrents[i].index = (i + 1);
      }
      $scope.items = $scope.torrents;
      // $scope.items = [
      //   {
      //     title: "Ghostbuster 1984 Deluxe Ed UHD BluRay DV-HDR10 10Bit 2160p AC-3 TrueHD7 1 At,mos + Multi HEVC-d3g",
      //     size: "4.21 GB",
      //     dateUploadedTxt: getDateUploadedTxt(parseFloat(new Date("Fri, 27 Sep 2024 15:53:33 +0000").getTime() / 1000)),
      //     index: 1
      //   }
      // ];
      document.getElementById('media').scrollTop = 0;
      return;
    }
    let jsonKey = '';
    if ($scope.selectedCategory == 'top') {
      jsonKey = 'precompileddata_top100_';
    } else {
      jsonKey = 'apibay.orgq.php?q=category:';
    }
    if ($scope.selectionOn == 'hd') {
      jsonKey += '207';
    } else if ($scope.selectionOn == 'tv4k') {
      jsonKey += '212';
    } else if ($scope.selectionOn == 'tv') {
      jsonKey += '208';
    } else if ($scope.selectionOn == 'fourk') {
      jsonKey += '211';
    }
    if ($scope.selectedCategory == 'top') {
      jsonKey += '.json'
    }
    jsonKey += '.txt';
    for (let item of $scope.pbJson) {
      if (item.section == jsonKey) {
        if ($scope.selectedDetails.filter == 'all') {
          $scope.items = item.items;
        } else {
          $scope.items = item.items.filter(item => item.isNew == true);
        }
        if (
          $scope.selectedDetails.filterRecent == 'on' &&
          $scope.selectedCategory == 'top'
        ) {
          $scope.items = item.items.filter(item =>
            item.dateUploadedTxt.toLowerCase().includes('day')
          );
        }
        if (
          $scope.selectedDetails.filterText &&
          $scope.selectedDetails.filterText.length > 0
        ) {
          $scope.items = $scope.items.filter(item =>
            item.title
              .toLowerCase()
              .includes($scope.selectedDetails.filterText.toLowerCase())
          );
        }
        $scope.lastupdated = new Date(item.lastUpdated);
        document.getElementById('media').scrollTop = 0;
        return;
      }
    }
  }

  function getDateUploadedTxt(val) {
    const dateUploaded = moment(parseFloat(val) * 1000);
    if (dateUploaded.isSame(moment().add(-1, 'days'), "day")) {
      return "Y'day " + dateUploaded.format('HH:mm');
    } else if (dateUploaded.isSame(new Date(), "day")) {
      return "Today " + dateUploaded.format('HH:mm');
    } else {
      return dateUploaded.format('DD/MM HH:mm');
    }
  }

  function displayPbDetails(compressedDetails) {
    const details = LZString.decompressFromUTF16(compressedDetails);
    const jsonDetails = JSON.parse(details);
    $scope.pbJson = jsonDetails.pbItems;
    $scope.torrents = jsonDetails.torrents;
    for (let section of $scope.pbJson) {
      if (section.section.toLowerCase().includes('top')) {
        for (let i = 0; i < section.items.length; i++) {
          section.items[i].index = i + 1;
        }
      }
    }
    setItems();
    setNewLabels();
    for (let section of $scope.pbJson) {
      for (let i = 0; i < section.items.length; i++) {
        section.items[i].dateUploadedTxt = getDateUploadedTxt(section.items[i].dateUploaded);
      }
      if (section.section.toLowerCase().includes('top')) {
        for (let i = 0; i < section.items.length; i++) {
          section.items[i].index = i + 1;
        }
      }
    }
    setItems();
    setNewLabels();
  }

  $scope.applyFilter = function (fromRecent) {
    if (!fromRecent) {
      $scope.selectedDetails.filterRecent = false;
    } else {
      $scope.selectedDetails.filter = 'all';
    }
    setItems();
  };
};
