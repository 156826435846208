module.exports = function($rootScope, $document, $compile, $timeout) {
  const dialogHelper = {};

  dialogHelper.LOADING_MODAL_ID = 'generic-loading-modal';
  dialogHelper.ALERT_MODAL_ID = 'generic-alert-modal';

  dialogHelper.closeOthers = function(id) {
    $rootScope.$broadcast('close-dialog', {
      openID: id
    });
  };

  dialogHelper.closeDialog = function(data) {
    $rootScope.$broadcast('close-dialog', data);
  };

  dialogHelper.shakeModal = function() {
    $rootScope.$broadcast('shake-dialog');
  };

  dialogHelper.openDialog = function(dialogId) {
    $rootScope.$broadcast('open-dialog', {
      dialogId: dialogId
    });
  };

  dialogHelper.showAlert = function(alertText) {
    $rootScope.alertText = alertText;
    const alertModal = $document[0].getElementById(this.ALERT_MODAL_ID);
    if (!alertModal) {
      const elementHtml =
        '<modal id="' +
        this.ALERT_MODAL_ID +
        '" cancel-action="true" cancel-text="Ok"></modal>';
      $document[0].body.append(elementHtml);
      $compile(elementHtml)($rootScope);
    }
    $timeout(function() {
      $rootScope.$broadcast('open-dialog', {
        dialogId: dialogHelper.ALERT_MODAL_ID
      });
    });
  };

  dialogHelper.showLoading = function(loadingText) {
    $rootScope.loadingText = loadingText;
    const loadingModal = $document[0].getElementById(this.LOADING_MODAL_ID);
    if (!loadingModal) {
      const elementHtml = '<modal id="' + this.LOADING_MODAL_ID + '"></modal>';
      $document[0].body.append(elementHtml);
      $compile(elementHtml)($rootScope);
    }
    $timeout(function() {
      $rootScope.$broadcast('open-dialog', {
        dialogId: dialogHelper.LOADING_MODAL_ID
      });
    });
  };

  return dialogHelper;
};
