'use strict';

// @ngInject
module.exports = function () {

    return {
        scope: {
            id: '@'
        },
        templateUrl: '/public/scripts/directives/pb-button/pb-button.template.html',
        restrict: 'E',
        replace: true,
        transclude: true,
        controllerAs: 'vm',
        controller: ['$scope', '$element', '$timeout', '$window', modalCtrl],
        bindToController: true
    };
};

function modalCtrl($scope, $element, $timeout, $window) {}