require('angular');
require('angular-touch');
require('angular-sanitize');
require('angular-route');
require('moment');
require('angular-moment');

require('./factories');
require('./directives');
require('./controllers');
require('./routes');
require('./swversion');

const USE_SERVICE_WORKER = true;
window.VAPID_PUBLIC_KEY = 'BF0Qdtcylin_JodRb1LU1opcmKl4ufXW4rlmijNOFNcGRoz7TlLRw-mTFpV-pGy0uEhsUOUZgkzaVcU76CDWajo';

if ('serviceWorker' in navigator && USE_SERVICE_WORKER) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/public/sw.js').then(
      function(registrationResult) {
        // Registration was successful
        console.log(
          'ServiceWorker registration successful with scope: ',
          registrationResult.scope
        );
        window.registration = registrationResult;
      },
      function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      }
    );
  });
}

(function() {
  'use strict';
  angular
    .module('pbpwa', [
      'ngTouch',
      'ngSanitize',
      'ngRoute',
      'angularMoment',
      'pb.factories',
      'pb.controllers',
      'pb.routes',
      'pb.directives'
    ])
    .service('apicheck', [
      '$rootScope',
      '$q',
      function($rootScope, $q) {
        this.responseError = function(rejection) {
          if (rejection.status === 401) {
            $rootScope.$emit('authenticationError');
            return;
          }
          $q.reject(rejection);
        };
      }
    ])
    .config(function($httpProvider) {
      $httpProvider.interceptors.push('apicheck');
    })
    .controller('ApplicationController', [
      '$scope',
      '$location',
      '$rootScope',
      '$document',
      '$timeout',
      '$http',
      'dialogHelper',
      function($scope, $location, $rootScope, $document, $timeout, $http, dialogHelper) {
        // Set client IP Address
        $rootScope.clientIp = window.parent.clientIp;
        // Set auth header in all requests
        $http.defaults.headers.common['x-access-token'] = localStorage.getItem(
          'authToken'
        );

        $document.on('visibilitychange', function(event) {
          if (!$document[0].hidden) {
            $rootScope.$broadcast('checkLoadData');
          } else {
            $rootScope.$broadcast('stopDownloadsCheck');
          }
          navigator.clearAppBadge();
        });

        $scope.userLoginDetails = {
          username: localStorage.getItem('savedUsername'),
          password: localStorage.getItem('savedPassword'),
          rememberMe: true
        };

        $rootScope.$on('authenticationError', function(event, args) {
          $scope.showAuthenticationLogin();
        });

        $scope.showAuthenticationLogin = function() {
          $scope.unselectMore();
          $scope.unselectItem();
          $timeout(function() {
            dialogHelper.openDialog('show-login');
          }, 300);
        };

        $scope.doLogin = function() {
          $scope.loggingIn = true;
          $timeout(function() {
            const loginData = {
              username: $scope.userLoginDetails.username,
              password: $scope.userLoginDetails.password
            };
            $http({
              url: '/dynamic/login',
              method: 'POST',
              data: loginData
            }).then(function(response) {
              if (response.data.status == 'success') {
                if ($scope.userLoginDetails.rememberMe) {
                  localStorage.setItem(
                    'savedUsername',
                    $scope.userLoginDetails.username
                  );
                  localStorage.setItem(
                    'savedPassword',
                    $scope.userLoginDetails.password
                  );
                }
                const authToken = response.data.authToken;
                localStorage.setItem('authToken', authToken);
                $http.defaults.headers.common['x-access-token'] = authToken;
                dialogHelper.closeDialog();
                $timeout(function() {
                  $scope.loggingIn = false;
                }, 500);
                $rootScope.$broadcast('checkLoadData');
              } else {
                $scope.loggingIn = false;
                dialogHelper.shakeModal();
              }
            });
          }, 700);
        };

        $scope.progress = 0;
        $rootScope.$on('xhrProgress', function(event, url, data) {
          if (data != 100) {
            $scope.progress = data;
          }
          $scope.$apply();
        });
      }
    ])
    .decorator('$xhrFactory', [
      '$delegate',
      '$rootScope',
      function($delegate, $rootScope) {
        return function(method, url) {
          var xhr = $delegate(method, url);
          xhr.addEventListener('progress', function(event) {
            $rootScope.$broadcast(
              'xhrProgress',
              url,
              (event.loaded / event.total) * 100
            );
          });
          return xhr;
        };
      }
    ]);
})();

function goFullscreen() {
  // Must be called as a result of user interaction to work
  mf = document.getElementById('trailer-video');
  mf.webkitRequestFullscreen();
  mf.style.display = '';
}
