'use strict';

const { prettySize } = require('pretty-size');

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// @ngInject
module.exports = function () {
  return {
    restrict: 'E',
    templateUrl: '/public/scripts/directives/more/more.template.html',
    transclude: true,
    controller: [
      '$scope',
      '$element',
      '$rootScope',
      '$timeout',
      '$http',
      '$window',
      'dialogHelper',
      moreCtrl
    ]
  };
};

function moreCtrl($scope, $element, $rootScope, $timeout, $http, $window, dialogHelper) {
  const DIALOG = 'MORE';
  $scope.dialogHelper = dialogHelper;
  $scope.selectedDownload = {};
  $scope.searchDetails = {
    searchText: '',
    sort: 'relevance',
    newSort: '',
    existingSearchText: '',
    searchResults: []
  };

  $scope.$on('close-more', function (event, data) {
    let closeThisDialog = true;
    if (data) {
      if (data.id !== DIALOG) {
        closeThisDialog = false;
      }
    }
    if (closeThisDialog && $scope.moreItemDetails) {
      $scope.moreItemDetails = null;
    }
  });

  $scope.doSearchDownload = function () {
    $rootScope.$broadcast('download-to-aria', {
      infoHash: $scope.searchDetails.selectedResult.info_hash,
      name: $scope.searchDetails.selectedResult.name,
      type: 'furk'
    });
  };

  $scope.updateSearch = function () {
    if ($scope.searchDetails.newSort != $scope.searchDetails.sort) {
      $scope.searchDetails.sort = $scope.searchDetails.newSort;
      $scope.doSearch(true);
    }
  };

  $scope.showSize = function (sizeVal) {
    return prettySize(parseFloat(sizeVal));
  };

  $scope.allowNotifications = function () {
    window.Notification.requestPermission().then(function (response) {
      if (response == "granted") {
        window.registration.pushManager.subscribe({
          applicationServerKey: urlBase64ToUint8Array(
            window.VAPID_PUBLIC_KEY
          ),
          userVisibleOnly: true,
        }).then(function (subscription) {
          $http.post('/dynamic/save-subscription', JSON.stringify(subscription)).then(function (response) {
            if (response.data == 'ok') {
              dialogHelper.showAlert(
                'Notifications are now allowed'
              );
            } else {
              dialogHelper.showAlert(
                'Notifications could not be enabled'
              );              
            }
          });
        });
      }
    });
  };

  $scope.downloadToAria = function () {
    console.log('downloading to aria');
    $rootScope.$broadcast('download-to-aria', {
      infoHash: $scope.selectedDownload.infoHash,
      name: $scope.selectedDownload.name,
      type: $scope.selectedDownload.type,
      id: $scope.selectedDownload.id
    });
  };

  $scope.doSearch = function (updating) {
    let searchText = '';
    if (!updating) {
      searchText = $scope.searchDetails.searchText;
      $scope.searchDetails.existingSearchText = searchText;
      $scope.searchDetails.searchText = '';
      $scope.searchDetails.sort = 'relevance';
      $scope.searchDetails.searchResults = [];
    } else {
      searchText = $scope.searchDetails.existingSearchText;
    }
    if (searchText.length == 0) {
      dialogHelper.closeDialog();
      return;
    }
    dialogHelper.showLoading('Loading Search results, please wait');
    let url =
      '/dynamic/search?text=' +
      searchText +
      '&sort=' +
      $scope.searchDetails.sort +
      '&now=' +
      new Date().valueOf();
    $http.get(url).then(function (response) {
      $scope.searchDetails.searchResults = response.data.files;
      if (!$scope.searchDetails.searchResults || $scope.searchDetails.searchResults.length == 0) {
        dialogHelper.showAlert(
          'There are no search results for the query specified'
        );
      } else {
        dialogHelper.closeDialog();
        $scope.moreItemDetails = 'searchResults';
      }
    });
  };

  $scope.showMoreItem = function (moreType, event) {
    $scope.moreItemDetails = moreType;
    if (moreType == 'downloads') {
      getActiveDownloads();
    }
  };

  $scope.refreshDownloads = function () {
    getActiveDownloads();
  };

  $scope.addDownload = function () {
    $scope.downloadDetails = {
      url: ''
    };
    dialogHelper.openDialog('add-download');
  };

  $scope.doAddDownload = function () {
    if (!$scope.downloadDetails.url || $scope.downloadDetails.url.length == 0) {
      return;
    }
    $timeout(function () {
      dialogHelper.showLoading('Checking downloads, please wait');
      let url =
        '/dynamic/adddownload?url=' +
        escape($scope.downloadDetails.url) +
        '&now=' +
        new Date().valueOf();
      $http.get(url).then(function (response) {
        $timeout(function () {
          getActiveDownloads();
        }, 2500);
      });
    }, 300);
  };

  $scope.resetSWCache = function () {
    console.log('here!!');
    dialogHelper.closeDialog();
    window.location.reload(true);
    // dialogHelper.closeDialog();
  };

  $scope.getMediaSourceUrl = function (inpSection) {
    for (let section of $scope.pbJson) {
      if (section.section.toLowerCase().includes(inpSection.toLowerCase())) {
        return section.url;
      }
    }
    return 'Unknown';
  };

/*
  function getActiveDownloads() {
    let url = '/dynamic/downloads?now=' + new Date().valueOf();
    $http.get(url).then(function (response) {
      if (response) {
        $scope.downloadList = response.data;
      }
    });
  }
  */
}
