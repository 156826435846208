'use strict';

// @ngInject
module.exports = function () {
  return {
    restrict: 'E',
    scope: {
      selectedDetails: '=selectedDetails'
    },
    templateUrl: '/public/scripts/directives/details/details.template.html',
    transclude: true,
    controller: [
      '$scope',
      '$element',
      '$timeout',
      '$http',
      '$sce',
      '$window',
      '$rootScope',
      'dialogHelper',
      detailsCtrl
    ]
  };
};

function detailsCtrl(
  $scope,
  $element,
  $timeout,
  $http,
  $sce,
  $window,
  $rootScope,
  dialogHelper
) {
  $scope.youTubeTrailer = '';
  $scope.trailerOn = false;
  $scope.imdbClicked = false;
  $scope.trailerClicked = false;
  $scope.loadingManualIMDB = false;

  $scope.$watch(
    'selectedDetails',
    function () {
      if ($scope.selectedDetails.fullDetails) {
        if ($scope.selectedDetails.selectedItem.imdb) {
          let youTubeTrailerId = null;
          if ($scope.selectedDetails.selectedItem.imdb.youTubeTrailerId) {
            youTubeTrailerId = new String($scope.selectedDetails.selectedItem.imdb.youTubeTrailerId);
            $scope.selectedDetails.selectedItem.youTubeTrailerId = youTubeTrailerId;
          }
          if ((!$scope.selectedDetails.selectedItem.imdb.Ratings || $scope.selectedDetails.selectedItem.imdb.Ratings.length == 0 || !$scope.selectedDetails.selectedItem.imdb.rating) && (!$scope.selectedDetails.selectedItem.refreshedIMDB)) {
            $scope.updateImdb($scope.selectedDetails.selectedItem.imdb.imdbID, true);
            $scope.selectedDetails.selectedItem.refreshedIMDB = true;
          }
          if (!youTubeTrailerId) {
            $http
              .get(
                '/static/trailer?imdbId=' +
                $scope.selectedDetails.selectedItem.imdb.imdbID
              )
              .then(function (response) {
                if (
                  response.status == 200 &&
                  response.data &&
                  response.data.length > 0
                ) {
                  $scope.selectedDetails.selectedItem.imdb.youTubeTrailerId =
                    response.data;
                  new YT.Player('trailer-video', {
                    events: {
                      onReady: function (evt) { }
                    }
                  });
                }
              });
          } else {
            new YT.Player('trailer-video', {
              events: {
                onReady: function (evt) { }
              }
            });
          }
          $scope.selectedOption = 'imdb';
        } else {
          $scope.selectedOption = 'info';
        }
      }
    },
    true
  );

  $scope.showLargeThumbnail = function () {
    dialogHelper.openDialog('large-thumbnail');
  };

  $scope.hideLargeThumbnail = function () {
    dialogHelper.closeDialog();
  };

  $scope.showTrailer = function () {
    $scope.trailerClicked = true;
    $scope.trailerOn = true;
    $scope.youTubeTrailer = $sce.trustAsResourceUrl(
      'https://www.youtube.com/embed/' +
      $scope.selectedDetails.selectedItem.imdb.youTubeTrailerId +
      '?enablejsapi=1&autoplay=1'
    );
    $timeout(function () {
      $scope.trailerClicked = false;
    }, 300);
  };

  $scope.closeTrailer = function () {
    $scope.trailerClicked = false;
    $scope.trailerOn = false;
  };

  $scope.setDetailSelection = function (val) {
    $scope.selectedOption = val;
  };

  $scope.unselectItem = function () {
    $scope.selectedDetails.fullDetails = false;
  };

  $scope.doDownload = function () {
    dialogHelper.openDialog('download-box');
  };

  $scope.showPoster = function (poster, isManualSearch) {
    $scope.isManualSearch = isManualSearch;
    $scope.posterOn = poster;
    dialogHelper.openDialog('poster-on');
  };

  $scope.hidePoster = function () {
    if (!$scope.isManualSearch) {
      dialogHelper.openDialog('find-imdb-modal');
    } else {
      dialogHelper.openDialog('enter-imdb-details-modal');
    }
  };

  $scope.imdbSearch = function () {
    console.log($scope.imdbFindInfo);
    $scope.loadingManualIMDB = true;
    $scope.imdbResults = [];
    let url = '/dynamic/findManualImdb?now=' + new Date().valueOf();
    const postData = {
      title: $scope.imdbFindInfo.title,
      year: $scope.imdbFindInfo.year,
      imdbId: $scope.imdbFindInfo.imdbId,
      cat: $scope.selectedDetails.selectionOn
    };
    $http.post(url, JSON.stringify(postData)).then(function (response) {
      $timeout(function () {
        if (response.data && response.data.Search) {
          $scope.imdbResults = response.data.Search;
        } else if (response.data && response.data.Title) {
          $scope.updateImdb(response.data.imdbID);
        }
        $scope.loadingManualIMDB = false;
      });
    });
  };

  $scope.findImdbInfo = function () {
    $scope.imdbResults = [];
    dialogHelper.showLoading('Finding IMDB Info, please wait');
    let url = '/dynamic/findImdb?now=' + new Date().valueOf();
    const postData = {
      title: $scope.selectedDetails.selectedItem.title,
      cat: $scope.selectedDetails.selectionOn,
      titleSearch: false
    };
    $http.post(url, JSON.stringify(postData)).then(function (response) {    
      if (
        !response.data ||
        response.data == 'error' ||
        response.data.Response == 'False'
      ) {
        if (response.data && response.data.Error && response.data.Error == 'Too many results.') {
          postData.titleSearch = true;
          $http.post(url, JSON.stringify(postData)).then(function (response) {    
            dialogHelper.closeDialog();
            if (response.data && response.data.Title) {
              $scope.updateImdb(response.data.imdbID);
            } else {
              $timeout(function () {
                $scope.imdbFindInfo = response.data.information;
                dialogHelper.openDialog('enter-imdb-details-modal');
              });
            }
          });
        } else {
          dialogHelper.closeDialog();
          $timeout(function () {
            $scope.imdbFindInfo = response.data.information;
            dialogHelper.openDialog('enter-imdb-details-modal');
          });
        }
      } else {
        dialogHelper.closeDialog();
        $scope.searchResults = response.data.Search;
        if (response.data.Title) {
          $scope.updateImdb(response.data.imdbID);
        } else if (response.data.Search.length > 1) {
          $timeout(function () {
            dialogHelper.openDialog('find-imdb-modal');
          });
        } else {
          $scope.updateImdb(response.data.Search[0].imdbID);
        }
      }
    });
  };

  $scope.updateImdb = function (imdbId, hideLoadingDialog) {
    if (!hideLoadingDialog) {
      dialogHelper.showLoading('Updating with IMDB Info, please wait');
    }
    const postData = {
      imdb: imdbId,
      id: $scope.selectedDetails.selectedItem.id
    };
    let url = '/dynamic/updateImdb?now=' + new Date().valueOf();
    $http.post(url, JSON.stringify(postData)).then(function (response) {
      dialogHelper.closeDialog();
      if (!response.data || !response.data.imdb) {
        if (!hideLoadingDialog) {
          dialogHelper.showAlert('Unable to update IMDB details for this title');
        }
      } else {
        $scope.selectedDetails.selectedItem.imdb = response.data.imdb;        
      }
    });
  };

  $scope.copyItem = function() {
    navigator.clipboard.writeText('magnet:?xt=urn:btih:' + $scope.selectedDetails.selectedItem.infoHash);
    dialogHelper.showAlert('Magnet link copied to clipboard');
  };

  $scope.refreshItem = function () {
    dialogHelper.showLoading('Refreshing, please wait');
    let url = '/dynamic/refresh?now=' + new Date().valueOf();
    const postData = {
      infoHash: $scope.selectedDetails.selectedItem.infoHash,
      url: $scope.selectedDetails.selectedItem.url
    };
    $http.post(url, JSON.stringify(postData)).then(function (response) {
      if (
        typeof response.data === 'string' ||
        response.data instanceof String
      ) {
        console.log('invalid data:', response.data);
      } else {
        $timeout(function () {
          if (response.data.comments && response.data.comments.length > 0) {
            $scope.selectedDetails.selectedItem.comments =
              response.data.comments;
          }
          if (response.data.imdb) {
            $scope.selectedDetails.selectedItem.imdb = response.data.imdb;
          }
          if (response.data.info) {
            $scope.selectedDetails.selectedItem.info = response.data.info;
          }
        });
      }
      dialogHelper.closeDialog();
    });
  };

  $scope.viewInImdb = function () {
    $scope.imdbClicked = true;
    $timeout(function () {
      $scope.imdbClicked = false;
    }, 300);
    $timeout(function () {
      $window.location.href =
        'https://www.imdb.com/title/' +
        $scope.selectedDetails.selectedItem.imdb.imdbID +
        '/';
    }, 500);
  };

  $scope.$on('download-to-aria', function (event, data) {
    if (data) {
      let url = '/dynamic/' + data.type + '?infohash=' + data.infoHash + '&cat=Movies';
      url += '&title=' + data.name;
      url += '&id=' + data.id;
      postDownloadMessage(url);
    }
  });

  $scope.agreeRestartHa = function () {
    dialogHelper.closeDialog();
    let url = '/dynamic/restartHa';
    $http.get(url).then(function (response) {
      dialogHelper.openDialog('restartHa');      
    });
  };

  $scope.agreeDownload = function () {
   dialogHelper.closeDialog();
   let url = '';
   if ($scope.selectedDetails.selectedItem.infoHash) {
    url = '/dynamic/downloadToTransmission?infohash=' + $scope.selectedDetails.selectedItem.infoHash + '&name=' + $scope.selectedDetails.selectedItem.title + '&category=' + $scope.selectedDetails.selectionOn
    if ($scope.selectedDetails.selectedItem.imdb) {
      url += '&imdbID=' + $scope.selectedDetails.selectedItem.imdb.imdbID
    }
  } else {
    url = '/dynamic/downloadTorrentToTransmission?url=' + $scope.selectedDetails.selectedItem.url + '&name=' + $scope.selectedDetails.selectedItem.title + '&category=fourk' + '&origTorrentId=' + $scope.selectedDetails.selectedItem.origTorrentId;
    if ($scope.selectedDetails.selectedItem.imdb) {
      url += '&imdbID=' + $scope.selectedDetails.selectedItem.imdb.imdbID
    }
  }
   postDownloadMessage(url);
  };

  function postDownloadMessage(url) {
    $http.get(url).then(function (response) {
      $scope.downloadStatus = 'failed';
      if (response.data == 'donefurk') {
        $scope.doneMessage =
          'The file has been download to furk and is availble from there.';
        $scope.downloadStatus = 'success';
      } else if (response.data == 'done') {
        $scope.doneMessage = 'The file is being downloaded to furk.';
        $scope.downloadStatus = 'success';
      } else if (response.data == 'nowdownloading') {
        $scope.doneMessage = 'The file is being downloaded to the NAS box.';
        $scope.downloadStatus = 'success';
      } else if (response.data == 'generror') {
        $scope.doneMessage = 'The file failed to download.  Please try again.';
      } else {
        $scope.doneMessage = 'An error occurred in adding this file.';
      }
      if ($scope.downloadStatus != 'success') {
        dialogHelper.openDialog('doneDownloading');
      } else {
        $rootScope.$broadcast('show-checkmark');
      }
    });
  }

  $scope.getImdbWidth = function () {
    if (
      $scope.selectedDetails.selectedItem.comments &&
      $scope.selectedDetails.selectedItem.comments.length > 0
    ) {
      return '33%';
    }
    return '50%';
  };

  $scope.getInfoWidth = function () {
    if (
      $scope.selectedDetails.selectedItem.comments &&
      $scope.selectedDetails.selectedItem.comments.length > 0 &&
      $scope.selectedDetails.selectedItem.imdb
    ) {
      return '33%';
    } else if (
      ($scope.selectedDetails.selectedItem.comments &&
        $scope.selectedDetails.selectedItem.comments.length > 0) ||
      $scope.selectedDetails.selectedItem.imdb
    ) {
      return '50%';
    }
    return '100%';
  };
}
