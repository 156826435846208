'use strict';

// @ngInject
module.exports = function() {
  return {
    scope: {
      submitText: '@',
      submitAction: '&?',
      cancelText: '@',
      cancelAction: '&?',
      closeAction: '&?',
      isLoadingModal: '='
    },
    templateUrl: '/public/scripts/directives/modal/modal.template.html',
    restrict: 'E',
    replace: true,
    transclude: true,
    controllerAs: 'vm',
    controller: [
      '$scope',
      '$element',
      '$timeout',
      '$window',
      '$rootScope',
      'dialogHelper',
      modalCtrl
    ],
    bindToController: true
  };
};

function modalCtrl(
  $scope,
  $element,
  $timeout,
  $window,
  $rootScope,
  dialogHelper
) {
  const vm = this;
  const documentObj = $window.top.document;
  const body = angular.element(documentObj.body);

  $scope.backdropClose = function() {
    if ($scope.open) {
      $scope.closeDialog();
    }
  };

  $scope.keyPress = function(event) {
    if (event.keyCode === 27 && $scope.open) {
      // escape was pressed, close the window by calling cancel.
      $scope.closeDialog();
    }
  };

  $timeout(function() {
    body.append($element);
    let modalBackdrop = getModalBackdrop();
    if (!modalBackdrop) {
      body.append('<div id="modal-backdrop"></div>');
    }
  });

  $scope.closeDialog = function(keepModalOpen, exceptModalId) {
    $timeout(function() {
      if (exceptModalId == null || exceptModalId != $element[0].id) {
        if (vm.closeAction && $scope.open) {
          vm.closeAction();
        }
        $scope.open = false;
      }
      if (!keepModalOpen) {
        let modalBackdrop = angular.element(getModalBackdrop());
        modalBackdrop.removeClass('open');
      }
    });
  };

  $scope.$on('shake-dialog', function() {
    if ($scope.open) {
      $scope.shakeModal = true;
      $timeout(function() {
        $scope.shakeModal = false;
      }, 500);
    }
  });

  $scope.$on('close-dialog', function(event, data) {
    let keepModalOpen = false;
    let exceptModalId = null;
    if (data && data.keepModalOpen) {
      keepModalOpen = data.keepModalOpen;
    }
    if (data && data.exceptModalId) {
      exceptModalId = data.exceptModalId;
    }
    $scope.closeDialog(keepModalOpen, exceptModalId);
  });

  $scope.$on('open-dialog', function(event, data) {
    if (data.dialogId != $element[0].id) {
      return;
    }

    if ($element[0].id == dialogHelper.LOADING_MODAL_ID) {
      $scope.loadingText = $rootScope.loadingText;
    } else if ($element[0].id == dialogHelper.ALERT_MODAL_ID) {
      $scope.alertText = $rootScope.alertText;
    }

    dialogHelper.closeDialog({
      keepModalOpen: true,
      exceptModalId: data.dialogId
    });
    let modalBackdrop = angular.element(getModalBackdrop());
    modalBackdrop.addClass('open');
    dynamicStyling();
    $scope.open = true;
    body.on('keydown', $scope.keyPress);
    modalBackdrop.on('click', $scope.backdropClose);
  });

  $scope.$on('$destroy', function() {
    cleanUpListeners();
    $element.remove();
    angular.element(getModalBackdrop()).removeClass('open');
  });

  angular.element($window).on('resize', updateSizeOnResize);

  function getModalBackdrop() {
    return documentObj.getElementById('modal-backdrop');
  }

  function cleanUpListeners() {
    angular.element($window).off('resize', updateSizeOnResize);
    body.off('keydown', $scope.keyPress);
    let modalBackdrop = angular.element(getModalBackdrop());
    modalBackdrop.off('click', $scope.backdropClose);
  }

  function updateSizeOnResize() {
    if ($scope.open) {
      // We may have to manually resize on window resize.
    }
  }

  function dynamicStyling() {
    $timeout(function() {
      $element.css('margin-top', '-' + $element[0].offsetHeight / 2 + 'px');
      $element.css('margin-left', '-' + $element[0].offsetWidth / 2 + 'px');
    });
  }
}
