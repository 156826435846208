angular.module('pb.routes', ['ngRoute'])
    .config(function ($routeProvider, $locationProvider) {
        $routeProvider
            .when('/', {
                templateUrl: 'public/views/home/home.html',
                controller: 'homeController'
            })
            .when('/details', {
                templateUrl: 'public/views/full-details/full-details.html',
                controller: 'fullDetailsController'
            })
            .otherwise('/', {
                templateUrl: 'public/views/home/home.html',
                controller: 'homeController'
            })
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: true
        });
    });