'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
function convertBytesToPrettySize(bytes, isDecimal) {
  var thresh = isDecimal ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = isDecimal ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

var prettySize = exports.prettySize = convertBytesToPrettySize;